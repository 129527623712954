import { graphql, useStaticQuery, Link } from 'gatsby';
import React, { ReactNode } from 'react';
import 'reflect-metadata';
import Layout from '../layout';
import styled from 'styled-components';
import {  Column, Container, Row } from '../styles/grids';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

interface BioPageProps {
    children: ReactNode;
}

interface BioData {
    contentfulBio: {
        bio: {
            raw: any;
        }
    };
}

const SideColumns = styled.div`
  flex: 1;
  @media only screen and (max-width: 767px) {
    flex: 0;
  }
`;

const StyledLink = styled(Link)`
    text-decoration: underline;
    color: #CCABBA;
    &:hover{
        font-style: italic;
        cursor: pointer;
    }

  h1 {
      font-family: Inconsolata;
      font-weight: 200;
      font-size: 30px;
      margin: 5px;
  }
`;

const BioPage: React.FC<BioPageProps> = () => {

    const { contentfulBio }: BioData = useStaticQuery(graphql`
        query {
            contentfulBio {
                bio {
                    raw
                }
            }
        }
    `);

    return (
        <Layout>
            <Container>
                <Row>
                    <SideColumns />
                    <Column flex={3} marginBottom={'10vw'}>
                   {documentToReactComponents(
                        JSON.parse(contentfulBio.bio.raw),
                        bio_options()
                    )}
                    </Column>
                    <SideColumns />
                </Row>
            </Container>
        </Layout>
    );
};

/**
 * bio_options: Default rich text parsing options
 * for the bio page
 */
 const bio_options = () => {
    const option_node = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
          return (
                <p style={{fontSize: "18px"}}>{children}</p>
          )
        },
        [BLOCKS.HEADING_1]: (node: any, children: any) => {
          return(
            <h1 style={{fontSize: "48px", fontStyle: "italic"}}>{children}</h1>
          )
        },
        [BLOCKS.HEADING_2]: (node: any, children: any) => {
          return(
            <h2 style={{fontSize: "32px", fontStyle: "italic"}}>{children}</h2>
          )
        },
        [BLOCKS.HEADING_3]: (node: any, children: any) => {
          return(
            <h3 style={{fontSize: "24px", fontStyle: "italic"}}>{children}</h3>
          )
        },
        [BLOCKS.HEADING_4]: (node: any, children: any) => {
          return(
            <h4 style={{fontSize: "20px", fontStyle: "italic"}}>{children}</h4>
          )
        },
        [BLOCKS.HEADING_5]: (node: any, children: any) => {
          return(
            <h5 style={{fontSize: "18px", fontStyle: "italic"}}>{children}</h5>
          )
        },
      [INLINES.HYPERLINK]: (node: any, children: any) => {
            console.log(node)
            return (
              <StyledLink to={node.data.uri} target={'_blank'}>
                  {children}
              </StyledLink>
            )
          },
      }

    }
    return option_node
  }

export default BioPage;
